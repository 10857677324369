<template>
  <div
    class="flex flex-col w-full justify-self-center"
    :class="[
      contentPosition(contentPositionMobile, contentPositionDesktop),
      rowGap,
      rowGapDesktop,
      maxWidth,
      maxWidthDesktop,
      paddingHorizontal,
      paddingHorizontalDesktop,
    ]"
  >
    <component
      :is="title.component"
      v-for="title in content"
      :key="title._uid"
      v-editable="title"
      v-bind="title"
    />
  </div>
</template>

<script>
import GridMixin from '~/mixins/GridMixin.js'
export default {
  name: 'TextBlock',
  props: {
    ...GridMixin.props,
    content: {
      type: Array,
      required: false,
      default: () => [],
    },
    maxWidth: {
      type: String,
      required: false,
      default: '',
    },
    maxWidthDesktop: {
      type: String,
      required: false,
      default: '',
    },
    texts: {
      type: Array,
      required: false,
      default: () => [],
    },
    buttons: {
      type: Array,
      required: false,
      default: () => [],
    },
    buttonsAlign: {
      type: String,
      required: false,
      default: 'left',
    },
    rowGap: {
      type: String,
      required: false,
      default: 'space-y-0',
    },
    rowGapDesktop: {
      type: String,
      required: false,
      default: 'lg:space-y-0',
    },
    verticalMargin: {
      type: String,
      required: false,
      default: null,
    },
    horizontalMargin: {
      type: String,
      required: false,
      default: null,
    },
    contentPositionMobile: {
      type: String,
      required: false,
      default: '',
    },
    contentPositionDesktop: {
      type: String,
      required: false,
      default: '',
    },
    paddingHorizontal: {
      type: String,
      required: false,
      default: '',
    },
    paddingHorizontalDesktop: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    contentPosition(mobileValue, desktopValue) {
      const mobile = {
        'top-left': 'justify-start items-start text-left',
        'top-center': 'justify-start items-center text-center',
        'top-right': 'justify-start items-end text-right',
        'center-left': 'justify-center items-start text-left',
        'center-center': 'justify-center items-center text-center',
        'center-right': 'justify-center items-end text-right',
        'bottom-left': 'justify-end items-start text-left',
        'bottom-center': 'justify-end items-center text-center',
        'bottom-right': 'justify-end items-end text-right',
      }
      const desktop = {
        'top-left': 'lg:justify-start lg:items-start lg:text-left',
        'top-center': 'lg:justify-start lg:items-center lg:text-center',
        'top-right': 'lg:justify-start lg:items-end lg:text-right',
        'center-left': 'lg:justify-center lg:items-start lg:text-left',
        'center-center': 'lg:justify-center lg:items-center lg:text-center',
        'center-right': 'lg:justify-center lg:items-end lg:text-right',
        'bottom-left': 'lg:justify-end lg:items-start lg:text-left',
        'bottom-center': 'lg:justify-end lg:items-center lg:text-center',
        'bottom-right': 'lg:justify-end lg:items-end lg:text-right',
      }
      return mobile[mobileValue] + ' ' + desktop[desktopValue]
    },
  },
}
</script>
